

































































































































import {vxm} from "@/store";
import {Component, Vue, Watch} from 'vue-property-decorator';
import {CaseApiFactory, CaseDTO, CaseFilterDTO, CaseStatus, Configuration} from "@shared_vue/openapi/caseapi";
import 'firebase/auth';

import {ServiceBusClient, ServiceBusReceivedMessage, ServiceBusReceiver} from "@azure/service-bus";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import ButtonDateInput from "@/views/viewcomponents/ButtonDateInput.vue"
import axios from 'axios';
import firebase from "firebase";


@Component({components: { WaitModal, ButtonDateInput } })
    export default class CaseStack extends Vue {
    private showToast: boolean = true; 
    private ui = vxm.ui;
    private caseStore = vxm.case;
    private showWait: boolean = false;
    private auth = vxm.auth;
    private imagePath = process.env.VUE_APP_STATIC_DIR + 'images/icons/';
    private caseApi = CaseApiFactory(<Configuration>{basePath:process.env.VUE_APP_CASE_API_URL},process.env.VUE_APP_CASE_API_URL)
    private caseList: CaseDTO[] = []
    private waitABit = false;
    private serviceBusClient: ServiceBusClient | undefined;
    private receiver: ServiceBusReceiver|undefined;
    private filterState: boolean = false;
    private startDateModified: boolean = false;
    private endDateModified: boolean = false;
    private startDate: Date = this.defaultStartDate;
    private endDate: Date = this.defaultEndDate;
    private audio = new Audio(require('@/assets/sounds/case-alert.mp3')); // path to file
    private toasts: number = 0;
    private timeoutId: number | null = null;
    private intervalId: number | null = null;
    private checkedRows: Array<number> = [];
    private interval: number | undefined;
    
    @Watch('caseStore.caseFilter')
    onFilterChange(val: CaseFilterDTO, oldval: CaseFilterDTO){
      console.log('dude wut')
      let keys = Object.keys(val) as Array<keyof CaseFilterDTO>;
      if (keys.length===2 && val.startDate == this.defaultStartDate.toJSON() && val.endDate == this.defaultEndDate.toJSON()){
        this.filterState = false;
      } else {
        this.filterState = keys.length > 0;
      }
    }
    private filterMatches(arr2: string[]): boolean {
      const referenceArray = ["NEW", "OPEN", "REMINDER"];
  
      // Check if both arrays are of the same length
      if (referenceArray.length !== arr2.length) {
        return false;
      }
      return referenceArray.every(elem => arr2.includes(elem));
  }
    get isFiltered(){
      if (this.startDateModified || this.endDateModified){
        return true;
      }
      if (this.caseStore.caseFilter.caseStates){
        if (!this.filterMatches(this.caseStore.caseFilter.caseStates)){
          return true;
        }
      }
      if (this.caseStore.caseFilter.caseTypes){
        if (this.caseStore.caseFilter.caseTypes.length>0){
          return true;
        }
      }
      return false;
    }

    get localCaseList(){
      return this.caseList.map(caseItem => {
        // Parse the reported time as a UTC date
        const utcDate = new Date(caseItem.reported!);

        // Convert to GMT+2
        const localDate = new Date(utcDate.getTime() + 2 * 60 * 60 * 1000); // adding 2 hours in milliseconds

        // Format the date as DD/MM/YYYY
        const day = String(localDate.getDate()).padStart(2, '0');
        const month = String(localDate.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = localDate.getFullYear();

        // Format the time as HH:MM:SS
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        const seconds = String(localDate.getSeconds()).padStart(2, '0');
        const formattedTime = `${hours}:${minutes}:${seconds}`;

        // Combine the formatted date and time
        const formattedDateTime = `${year}/${month}/${day} ${formattedTime}`;

        // Return a new object with all properties of the original, but with the converted reported time
        return {
          ...caseItem,
          reported: formattedDateTime
        };
      })
    }
    
    @Watch('auth.idToken')
    onIdToken(val: boolean, oldval: boolean) {
      console.log('onidtoken')
      if (this.auth.usr)
      {
        this.caseStore.setOp(this.auth.usr?.uid);
      }
      if (this.waitABit){
        this.fetchCases()
      }
    }

    private processToast(){
      console.log('process toast')
      //should we reset filter or what?
      this.clearFilter();
    }
    
  @Watch('startDate')
  onStartDateChange(val: Date, oldval: Date) {
    this.startDateModified = val != this.defaultStartDate;
    console.log('onstartdatechange')
    let currentFilter = {...this.caseStore.currentFilter};
    currentFilter.startDate = val?.toJSON()??null;
    this.caseStore.updateFilter(currentFilter); 
  }

  @Watch('endDate')
  onEndDateChange(val: Date, oldval: Date) {
    console.log('onsenddatechange')
    this.endDateModified = val != this.defaultEndDate;
    let currentFilter = {...this.caseStore.currentFilter};
    currentFilter.endDate = val?.toJSON()??null;
    this.caseStore.updateFilter(currentFilter);
  }
    
    get defaultEndDate(){
 
      console.log('using default end date')
      let todaysDate = new Date();
      todaysDate.setDate(todaysDate.getDate()+1);
      todaysDate.setHours(0, 0, 0, 0);
      return  todaysDate;
    }
    
    get defaultStartDate(){
      
      //default
      console.log('using default start date')
      // monthAgo.setMonth(monthAgo.getMonth() -1)
      let todaysDate = new Date();
      todaysDate.setFullYear(2000); // effectively all time
      todaysDate.setHours(0, 0, 0, 0);
      return  todaysDate;
      
    }

  private visibilityType(which: string) : boolean{
    return this.caseStore.caseFilter.caseTypes?.includes(which)??false;
  }
    
    private visibility(which: string) : boolean{
      return this.caseStore.caseFilter.caseStates?.includes(which)??false;
    }
    
    get visibility_NEW(): boolean {
      return this.caseStore.caseFilter.caseStates?.includes('NEW')??false;
    }
    private filterCases(){
      
    }

      private async fetchCase(id: number){
      console.log('fetching case for id: ' + id);
        let response = await this.caseApi.caseGetCaseById(id)
        if (response.status == 200) {
          console.log(response.data)
          // need to deduplicate. If this id already exists just replace it
          let foundItem = this.caseList.find(a=>a.id==response.data.id);
          if (foundItem){
            let index = this.caseList.indexOf(foundItem);
            console.log('found, updating...');
            this.$set(this.caseList, index, response.data);

            // this.caseList[index]=response.data;
          } else {
            console.log('not found. Adding to list')
            const newArray = [response.data, ...this.caseList];
            this.caseList = newArray;
          }
        }
      }
      
      private clearFilter(){
        this.startDate = this.defaultStartDate;
        this.endDate = this.defaultEndDate;
        this.caseStore.resetFilter();
        this.fetchCases();
        
      }

      private async fetchCases(silent: boolean = false){
        this.caseStore.setCanCall(false);
        if (!this.auth.idToken) {
          this.waitABit = true;
          return;
        }

        try {
          console.log('go');
          if (!silent) {
            this.showWait = true;
          }
          // let freshToken = firebase.auth().currentUser?.getIdToken(true)
          //check for filter
          console.log(`is filtered: ${this.isFiltered}`)
          console.log(`filter objects: ${Object.keys(this.caseStore.currentFilter)}`)
          const { startDate, endDate } = this.caseStore.caseFilter;

          // Create a new object with default values for startDate and endDate if they are null or undefined
          const newCaseFilter = {
            ...this.caseStore.caseFilter,
            startDate: startDate ? new Date(startDate).toISOString() : this.defaultStartDate.toISOString(),
            endDate: endDate ? new Date(endDate).toISOString() : this.defaultEndDate.toISOString(),
            defaultStart: !this.startDateModified,
            defaultEnd: !this.endDateModified,
          } as CaseFilterDTO;
          let response = await this.caseApi.caseGetFilteredCases(newCaseFilter);
          //let response = await this.caseApi.caseGetCases();
          if (response.status == 200) {
            console.log(response.data)
            this.caseList = response.data;
          }
        } catch (e) {
          console.log(e)
          if (axios.isAxiosError(e)) {
            // Check if the error is due to a 401 Unauthorized response
            if (e.response && e.response.status === 401) {
              console.log("Unauthorized: Status code 401");
              //need to firebase auth
              console.log('refresh token');
              let token = await firebase.auth().currentUser?.getIdToken(true);
              axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            } else {
              // Handle other errors
              console.log("Error:", e.message);
            }
          } else {
            // Non-Axios error
            console.log("An unexpected error occurred:", e);
          }
        } finally {
          this.showWait = false;
        }
      }
   
      private async onClickCase(item:any, index:any, column:any, event:any){
        console.log("onclickcase")
        console.log('column: ' + column)
        if (column==='select') { //don't navigate
          return;
        }
        let id = item.id;
        //note that the flow for "NEW" is different to the others
        let actualCase = this.caseList.find(c=>c.id==id);        
        if (actualCase) {
          this.caseStore.setCurrentCase(actualCase);
          if (actualCase.callRequest){
            this.caseStore.setCanCall(true);
          }
          if (actualCase.status == CaseStatus.New) {
            //navigate to new flow
            if (actualCase.callRequest) { //save time
              // set to "open"
              let caseInstance: CaseDTO = {
                id: actualCase.id,
                status: CaseStatus.Open,
                officerId: this.auth.UserId
              };
              this.caseApi.caseUpdateCase(caseInstance); //no await but will this disappear?
            }
            this.$router.push(`/dashboard/casemanagement/${id}`)
          } else {
            //open case            
            this.$router.push(`/dashboard/casecapture/${id}`)            
          }
        }
      }

    private check (item:any, checked: boolean) { //the whole data row is passed in
      console.log(`${item.id} is ${checked}`)
      if (checked) {
        // Add the ID to the array if it's checked
        if (!this.checkedRows.includes(item.id)) {
          this.checkedRows.push(item.id);
        }
      } else {
        // Remove the ID from the array if it's unchecked
        this.checkedRows = this.checkedRows.filter(rowId => rowId !== item.id);
      }
    }
  


  mounted(){
      if (this.auth.usr) {
        this.caseStore.setOp(this.auth.usr?.uid)
      }
        this.scheduleJobAtOneMinutePastMidnight();

        this.configureSB();
        
        
        this.fetchCases();
      this.interval = window.setInterval(() => {
        this.backup();
      }, 60000);
      }
      
      beforeDestroy() {
        if (this.timeoutId) window.clearTimeout(this.timeoutId);
        if (this.intervalId) window.clearInterval(this.intervalId);
        if (this.interval) {
          clearInterval(this.interval);
        }
      }

    private async backup() {
      await this.fetchCases(true);
    }
    scheduleJobAtOneMinutePastMidnight() {
      const now = new Date();
      const oneMinutePastMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      oneMinutePastMidnight.setMinutes(1); // Set the time to 00:01
      oneMinutePastMidnight.setSeconds(0);
      oneMinutePastMidnight.setMilliseconds(0);
  
      const msUntilOneMinutePastMidnight = oneMinutePastMidnight.getTime() - now.getTime();
  
      this.timeoutId = window.setTimeout(() => {
        this.oneMinutePastMidnightJob();
  
        // Schedule the job to repeat every 24 hours
        this.intervalId = window.setInterval(() => {
          this.oneMinutePastMidnightJob();
        }, 86400000); // 86,400,000 milliseconds in a day
      }, msUntilOneMinutePastMidnight);
    }

    oneMinutePastMidnightJob() {
      // if using default dates we need to reshedule. Perhaps add a warning?
      if (!this.startDateModified){
        this.startDate = this.defaultStartDate;
      }
      
      this.endDate = this.defaultEndDate;
      
    }
  
      private checkNewCaseVisibility(event: any) : boolean {
        
        let passStates: boolean = true;
        let passTypes: boolean = true;
        let passStart: boolean = true;
        let passEnd: boolean = true;

        let actualReportDate = Date.parse(event.ReportedTime);
        //message.body: {"CaseId":"8","Event":5,"Source":"CaseController","Extra":"{}","Meta":null,"OwnerGuid":"OkVm0hNyuvMTMnyyc7l5wT5XjTR2","OpGuid":"","OriginalId":"","CaseType":"Missing Child","CaseState":"NEW","ReportedTime":"2022-01-26T07:16:47.3967545+00:00","EventName":"New Case","EventId":"fac1bdf4-0fe8-4309-9d6c-3cf1b92aa75b","EventTime":"2022-01-26T07:16:47.8162363+00:00"}
        console.log('checking case visibility')
        if (this.caseStore.caseFilter) {
          if (this.caseStore.caseFilter.caseStates) { //else true
            if (this.caseStore.caseFilter.caseStates.length > 0) { //else true
              passStates = !!this.caseStore.caseFilter.caseStates.includes(event.CaseState);
            }
            if (this.caseStore.caseFilter.caseTypes) {
              if (this.caseStore.caseFilter.caseTypes.length > 0) { //else true
                passTypes = !!this.caseStore.caseFilter.caseTypes.includes(event.CaseType);
              }
            }
            if (this.caseStore.caseFilter.startDate) {
              if (actualReportDate < Date.parse(this.caseStore.caseFilter.startDate)){
                passStart = false;
              }
            }
            if (this.caseStore.caseFilter.endDate) {
              if (actualReportDate > Date.parse(this.caseStore.caseFilter.endDate)){
                passEnd = false;
              }
            }
          } else {
            return true; //everything visible with no filter
          }
          
        }
        return passStates && passTypes && passStart && passEnd;
    }
    
    private toasteMe(){
      this.toasts++;
    }

    private myMessageHandler = async (message: ServiceBusReceivedMessage) => { //type this?
      // your code here
      try {
        console.log('message.body: ' + JSON.stringify(message.body));
        // fetch just this case so we don't overwhelm everything all the time
        //if a filter is applied, we must not necessarily just fetch it. 
        //message.body: {"CaseId":"8","Event":5,"Source":"CaseController","Extra":"{}","Meta":null,"OwnerGuid":"OkVm0hNyuvMTMnyyc7l5wT5XjTR2","OpGuid":"","OriginalId":"","CaseType":"Missing Child","CaseState":"NEW","ReportedTime":"2022-01-26T07:16:47.3967545+00:00","EventName":"New Case","EventId":"fac1bdf4-0fe8-4309-9d6c-3cf1b92aa75b","EventTime":"2022-01-26T07:16:47.8162363+00:00"}
        if (message.body.EventName == "New Case") { //should we play a sound?
          try {
            await this.audio.play();
          } catch (e) {
            //suppress
          }
          // if (!this.checkNewCaseVisibility(message.body)) { //rather determine if this should be visible based on a variety of criteria. Otherwise show like a dot or something with a count
          //   //just alert
          //   console.log('just toasting');
          //   this.toasteMe();
          // } else {
            await this.fetchCase(message.body.CaseId);
          // }
        } else if (message.body.EventName == "Case Updated") {
          await this.fetchCase(message.body.CaseId);
        }
      } finally {
        // await this.receiver!.completeMessage(message);
      }
    };
    private myErrorHandler = async (args: any) => {
      console.log(
          `Error occurred with ${args.entityPath} within ${args.fullyQualifiedNamespace}: `,
          args.error
      );
    };
    
    private async massAction(which: string){
      this.showWait = true;
      try {
        if (this.checkedRows.length === 0) {
          alert('YOU MUST SELECT SOMETHING');
        }
        await this.caseApi.caseBulkUpdateCaseStates({
          status: which,
          caseIds: this.checkedRows
        });
        await this.fetchCases();
        this.checkedRows = [];
      } finally {
        this.showWait = false;
      }
    }
    
    
    private clickFilter(which: string){
      console.log(which);
      this.caseStore.toggleVisible(which);
      //refresh the view
      // this.fetchCases();
    }

  private clickFilter2(which: string){
    console.log(which);
    this.caseStore.toggleVisible2(which);
    //refresh the view
    // this.fetchCases();
  }
    
    private toggleFullView(){
      this.ui.fullView = !this.ui.fullView;
      this.ui.leftMinimize = !this.ui.leftMinimize;
      this.ui.rightMinimize = !this.ui.rightMinimize;
    }
  
      private async configureSB(){
        let connectionString = await this.caseApi.caseGetConnectionString();
        console.log('sb connectionstring: ' + connectionString.data);
        this.serviceBusClient = new ServiceBusClient('Endpoint=sb://dctestasb.servicebus.windows.net/;SharedAccessKeyName=ruleWithListen;SharedAccessKey=KxkiB+kJYD9wfEHS44Cpv+x5+L1RCc89l+pR89se4i8=;EntityPath=cases');//connectionString.data);
        this.receiver = this.serviceBusClient.createReceiver("cases", <string>this.auth.UserId, {receiveMode:'peekLock'});
    
        this.receiver.subscribe({
          processMessage: this.myMessageHandler,
          processError: this.myErrorHandler
        });
      }

      private fields = [
        {key: 'id'},
        {key: 'caseType', label: 'Case Type', _style: 'width:40px'},
        {key: 'status', _style: 'width:60px;'},
        {key: 'userName', label: 'Username and Surname', _style: 'min-width:200px;'},
        {key: 'contactNumber', label: 'Contact Number', _style: 'width:100px;'},
        {key: 'callRequest', label: 'Call', _style: 'width:50px;'},
        {key: 'caseNumber', label: 'IMIC Case #'},
        {key: 'complaint', _style: 'min-width:200px;'},
        {key: 'caseOfficer', label: 'Case Officer',_style: 'min-width:200px;'},
        {key: 'reported', _style: 'width:80px;'},
        {key: 'currentBranch', label: 'ERPC', _style: 'min-width:100px;', sorter: false,},
        {
          key: 'select',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ];

      private getColor(status: string) {
        switch (status.toLowerCase()) {
          case 'new':
            return '#f02b1d';
          case 'open':
            return '#eb8334';
          case 'cancelled':
            return '#ca56f5';
          case 'onhold':
            return '#ebcc34';
          case 'reminder':
            return '#ba34eb';
          case 'active':
            return '#ff8a30';
          case 'pending':
            return '#95eb34';
          case 'closed':
            return '#346beb';
          default:
            '#FFF'
        }
      };

      private getImage(caseType: any) {
        if (caseType) {
          return this.imagePath + caseType
        }
      };

      // get items2() {
      //   return this.items.map((item, id) => {
      //     return {...item, id}
      //   });
      // }

      private massSelect(){


      }

    }


